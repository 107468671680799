@tailwind base;
@tailwind components;
@tailwind utilities;

.title {
    @apply text-5xl text-gray-800 tracking-wider dark:text-gray-100 font-bold leading-tight mb-4;
}

.subtitle {
    @apply text-2xl text-gray-500 font-medium dark:text-gray-400 leading-10 mb-4;
}

.lead {
    @apply text-xl text-gray-500 dark:text-gray-300 font-medium leading-10 mb-4;
}

.btn {
    @apply inline-block m-1 p-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-violet-500 dark:bg-violet-600 hover:bg-violet-600 dark:hover:bg-violet-800 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 whitespace-nowrap;
}

.btn-secondary {
    @apply inline-block m-1 p-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-violet-600 dark:text-violet-200 bg-violet-200 hover:bg-violet-300 dark:bg-purple-800 dark:hover:bg-violet-700 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 whitespace-nowrap;
}

.btn-link {
    @apply inline-block m-1 p-2 border border-transparent text-sm font-semibold rounded-md text-purple-500 hover:text-purple-600 dark:text-purple-200 dark:hover:text-purple-300 bg-white dark:bg-slate-700 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 whitespace-nowrap;
}

.label {
    @apply inline-block bg-white dark:bg-slate-700 border border-violet-500 dark:border-purple-300 text-violet-500 dark:text-purple-300 text-xs font-semibold p-1 rounded tracking-wide whitespace-nowrap m-1;
}

.arc-top {
    @apply bg-gray-50 dark:bg-transparent dark:bg-gradient-to-r dark:from-slate-800 dark:to-slate-900 border border-b-0 border-gray-50 dark:border-slate-800 h-12;
    border-radius:50% 50% 0 0 / 100% 100% 0 0;
}

.arc-bottom {
    @apply bg-gray-50 dark:bg-transparent dark:bg-gradient-to-r dark:from-slate-800 dark:to-slate-900 border border-t-0 border-gray-50 dark:border-slate-800 h-12;
    border-radius:0 0 50% 50% / 0 0 100% 100%;
}

.A4 {
    max-width:21cm;
}

.details-overlay, .details-overlay-menu {
    @apply fixed top-0 left-0 bottom-0 right-0 pointer-events-none opacity-0 z-10 hidden;
}

details[open] .details-overlay {
    pointer-events:all;
    @apply bg-neutral-900 opacity-80 block;
}

details[open] .details-overlay-menu {
    pointer-events:all;
    @apply bg-gray-100;
}

.details-overlay-close {
    @apply fixed top-4 right-4 h-8 w-8 rounded-full hover:bg-neutral-800 z-30 text-white hidden;
}

.details-content {
    @apply z-20 hidden;
}

details[open] .details-content, details[open] .details-overlay {
    pointer-events:all;
    @apply block;
}

details[open] .details-overlay-close {
    pointer-events:all;
    @apply flex items-center justify-center;
}

@layer utilities {
    .top-inherit {
        top: inherit;
    }

    .right-inherit {
        right: inherit;
    }
}

.required:after {
    content:' *';
    @apply text-red-500;
}

:target {
    padding-top: 4rem;
    margin-top: -4rem;
}

input[type="number"] {
    -moz-appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

summary::marker, summary::-webkit-details-marker {
    display: none;
}